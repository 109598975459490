/* eslint-disable react-hooks/exhaustive-deps */
import BookNow from '../Elements/OpenedRoute/BookNow'
import CardCover from '../Elements/OpenedRoute/CardCover'
import Footer from '../Elements/Footer'
import React, { useEffect, useState } from 'react'
import RouteGoogleMapWrapper from '../Elements/RouteDesc/RouteGoogleMapWrapper'
import RouteList from '../Elements/RouteDesc/RouteList'
import Sponsors from '../Elements/Sponsors'
import StaySafe from '../Elements/OpenedRoute/StaySafe'
import UsePhone from '../Elements/OpenedRoute/UsePhone'
import Whitespace from 'components/reusable/Elements/Whitespace'
import clockIcon from './../../../icons/clock.png'
import rmSpaces from 'components/reusable/Functions/rmSpaces'
import routeIcon from './../../../icons/route.png'
import styled from 'styled-components'
import useScreenType from 'components/reusable/Hooks/useScreenType'
import { Params } from 'react-router-dom'
import { TKayakingRoute } from 'components/ProjectSpecificComponents/Stores/GlobalStore'
import { fetchAllRoutes, getCurrentPosition } from 'utils/utils'
import { useParams } from 'react-router-dom'
import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'

export type TRouteName = Readonly<Params<string>>

const OpenedRoute = () => {
  const { routeName }: TRouteName = useParams()
  const { isMobile } = useScreenType()
  const [route, setRoute] = useState<TKayakingRoute>()
  const store = useStore()
  const iconSize = isMobile ? 25 : 50
  const [currentLocation, setCurrentLocation] = useState(undefined)

  useEffect(() => {
    const getRoutesAndFindRoute = async () => {
      const data = await fetchAllRoutes()
      store.setKayakingRoutes(data)
      data.map((route) => rmSpaces(route.label) === routeName && setRoute(route))
    }

    getRoutesAndFindRoute()
  }, [])

  if (!route) {
    return null
  }

  const kmLabel = route.km ? `${route.km} km` : `? km`
  const lengthLabel = route.lengthFrom ? `${route.lengthFrom} - ${route.lengthTo}h` : ` ${route.lengthTo}h`

  const getLocation = () => {
    getCurrentPosition((coordinates) => setCurrentLocation(coordinates))
  }

  return (
    <OpenedRouteContainer>
      <CardCover route={route} />
      <Whitespace x='4' />
      <RouteHeader style={{ fontSize: isMobile ? 50 : 80 }}>{route.label}</RouteHeader>
      <Whitespace x='3' />

      <RouteDetails style={{ fontSize: isMobile ? 20 : 80 }}>
        <img src={routeIcon} alt='route-km' height={iconSize} />
        <label>{kmLabel}</label>
        <Whitespace x={isMobile ? '1' : '2'} h />
        <img src={clockIcon} alt='route-hours' height={iconSize} />
        <label>{lengthLabel}</label>
      </RouteDetails>
      <Whitespace x='2' />

      <RouteDesc onClick={getLocation}>{route.desc}</RouteDesc>
      <Whitespace x='4' />

      <BookNow route={route?.label} />

      <Whitespace x='6' />
      <RouteGoogleMapWrapper getLocation={getLocation} currentLocation={currentLocation} routeKMZ={route.routeKMZ} />
      <Whitespace x='2' />

      <UsePhone getLocation={getLocation} />
      <Whitespace x='4' />

      <BookNow route={route?.label} />
      <Whitespace x='4' />

      <StaySafe />
      <Whitespace x='4' />

      <RouteListBox>
        <RouteList />
      </RouteListBox>
      <Whitespace x='3' />

      <Sponsors />
      <Whitespace x='3' />

      <Footer />
    </OpenedRouteContainer>
  )
}

export default OpenedRoute

const OpenedRouteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RouteListBox = styled.p`
  width: 100vw;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RouteDesc = styled.p`
  width: 1200px;
  max-width: 90vw;
  margin-top: 50px;
  text-align: start;
  cursor: pointer;
`

const RouteHeader = styled.h1`
  width: 1200px;
  max-width: 90vw;
`

const RouteDetails = styled.div`
  width: 1200px;
  max-width: 90vw;
  display: flex;
  gap: 1.5vw;
  align-items: center;
  font-weight: 400;
`
