/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'
import { replaceEl } from 'utils/utils'

type Props = {
  getLocation: () => void
}

const UsePhone = observer(({ getLocation }: Props) => {
  const {
    translate: {
      buttons: { clickHere },
      map: { usePhone }
    }
  } = useStore()

  useEffect(() => {
    const ClickHereBtn = (
      <label className='click_here' onClick={getLocation}>
        {clickHere}
      </label>
    )
    replaceEl('clickHere-btn', ClickHereBtn)
  }, [usePhone])

  return <UsePhoneContainer dangerouslySetInnerHTML={{ __html: usePhone }} />
})

export default UsePhone

const UsePhoneContainer = styled.div`
  margin: 0px 20px;
  max-width: 900px;
  width: 95vw;
`
