/* eslint-disable no-undef */
import CurrentLocationMarker from './CurrentLocationMarker'
import React from 'react'
import ReactDOM from 'react-dom/client'
import locationOff from 'icons/location.svg'
import styled from 'styled-components'
import { GoogleMap, KmlLayer, useJsApiLoader } from '@react-google-maps/api'
import { IPosition } from 'utils/interfaces'
import { MAP_CENTER, MAP_OPTIONS } from 'components/ProjectSpecificComponents/Constants/MAP_CONSTANTS'
import { TKayakingRoute } from 'components/ProjectSpecificComponents/Stores/GlobalStore'
import { T_KMZ_TYPE } from './KMZ_LAYERS'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../Contexts/GlobalStoreContext'

interface Props {
  getLocation: (map: any) => void
  currentLocation?: IPosition
}

const GoogleMapWrapper = observer(({ getLocation, currentLocation }: Props) => {
  const store = useStore()

  const key: string = process.env.REACT_APP_MAP_KEY ?? ''
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: key })

  const onLoadMap = (map: any) => {
    const controlButtonDiv = document.createElement('div')
    const anch = ReactDOM.createRoot(controlButtonDiv)
    anch.render(<LocationIcon onClick={getLocation} src={locationOff} width='80%' height='80%' />)
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlButtonDiv)
  }

  return (
    <MapContainer>
      {isLoaded && (
        <>
          <GoogleMap
            id='map-container'
            mapContainerStyle={{
              width: '100%',
              height: '70vh',
              minHeight: 400,
              maxHeight: 900
            }}
            center={MAP_CENTER}
            onLoad={onLoadMap}
            options={{
              ...MAP_OPTIONS,
              zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
              }
            }}
            zoom={13}
          >
            <CurrentLocationMarker position={currentLocation} />

            <KmlLayer
              onLoad={() => {}}
              onUnmount={() => {}}
              options={{
                preserveViewport: true
              }}
            />
            {store.kayakingRoutes.map(({ label, difficulty, routeKMZ }: TKayakingRoute, index: number) => {
              return (
                <React.Fragment key={label}>
                  {(store.activeKMZ_KEYS.includes('All') || key === 'POI') && (
                    <KmlLayer
                      url={routeKMZ}
                      onLoad={() => {}}
                      onUnmount={() => {}}
                      options={{
                        preserveViewport: true
                      }}
                    />
                  )}

                  {store.activeKMZ_KEYS.includes(difficulty as T_KMZ_TYPE) && (
                    <KmlLayer
                      url={routeKMZ}
                      onLoad={() => {}}
                      onUnmount={() => {}}
                      options={{
                        preserveViewport: true
                      }}
                    />
                  )}
                </React.Fragment>
              )
            })}
          </GoogleMap>
        </>
      )}
    </MapContainer>
  )
})

export default GoogleMapWrapper

export const LocationIcon = styled.img`
  background-color: black;
  padding: 5px;
  width: 40px;
  margin: 10px;
  cursor: pointer;
`

const MapContainer = styled.div`
  position: 'relative';
`
