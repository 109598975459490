import Whitespace from 'components/reusable/Elements/Whitespace'
import useScreenType from 'components/reusable/Hooks/useScreenType'
import React from 'react'
import BookNow from '../OpenedRoute/BookNow'
import oneSeated from 'kayaks/1-seated.jpg'
import twoSeated from 'kayaks/2-seated.jpg'
import threeSeated from 'kayaks/3-seated.jpg'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'

const Kayaks = observer(() => {
  const { isMobile } = useScreenType()
  const height = isMobile ? '100px' : ''
  const {
    translate: { kayaks }
  } = useStore()

  const listOfKayaks = [
    { type: 'single', url: oneSeated },
    { type: 'double', url: twoSeated },
    { type: 'tripple', url: threeSeated }
  ]
  return (
    <KayaksContainer>
      <Heading>{kayaks.header}</Heading>
      <p dangerouslySetInnerHTML={{ __html: kayaks.description }} />

      <Whitespace x='4' />
      {listOfKayaks.map(({ type, url }) => {
        const { name, comment, seats, price } = kayaks[type]
        return (
          <React.Fragment key={type}>
            <KayakItem>
              <p style={{ minWidth: 150 }}>
                <b>{name}</b>
                {comment && (
                  <>
                    <br /> {comment}
                  </>
                )}
                <br /> {seats}
                <br /> {price}
              </p>
              <Whitespace h x='1' />
              <img src={url} alt='one' height={height} />
            </KayakItem>
            <Whitespace x='3' />
          </React.Fragment>
        )
      })}

      <p dangerouslySetInnerHTML={{ __html: kayaks.footer }} />

      <Whitespace x='5' />

      <Center>
        <BookNow />
      </Center>

      <Whitespace x='5' />
    </KayaksContainer>
  )
})

export default Kayaks

const KayaksContainer = styled.div`
  display: flex;
  width: 90vw;
  max-width: 1000px;
  flex-direction: column;
`

const Heading = styled.h1`
  font-size: 60px;
  padding-bottom: 50px;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const KayakItem = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`
