import React, { useEffect } from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@mui/material'
import { ECheckoutMode, ICheckoutOptions } from 'utils/interfaces'
import { getLineItems, ROUTE } from 'utils/utils'
import { loadStripe } from '@stripe/stripe-js'
import { observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '../Contexts/GlobalStoreContext'
import axios from 'axios'

const Checkout = observer(() => {
  const { bookingId } = useParams()
  const store = useStore()
  const navigate = useNavigate()

  let stripePromise
  const getStripe = () => {
    const STRIPE_TEST_KEY = process.env.REACT_APP_STRIPE_PUB_TEST
    const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUB_KEY
    const KEY = store.isTestMode ? STRIPE_TEST_KEY : STRIPE_KEY

    if (!stripePromise) {
      stripePromise = loadStripe(KEY)
    }
    return stripePromise
  }

  const redirectToCheckout = async (checkoutOptions: ICheckoutOptions) => {
    await axios.post('https://us-central1-rigakayaking-61916.cloudfunctions.net/createCheckoutSession', checkoutOptions)
    .then(function (response) {
        window.location.href = response.data;
    })
  }

  const hasCheckoutItemsCached = Boolean(store.kayakCount)

  useEffect(() => {
    if (hasCheckoutItemsCached) {
      const checkoutOptions: ICheckoutOptions = {
        lineItems: getLineItems(store.kayakCount),
        mode: ECheckoutMode.PAYMENT,
        successUrl: `${window.location.origin}${ROUTE.pay}/${bookingId}`,
        cancelUrl: `${window.location.origin}${ROUTE.toBookKayak}`,
        customer_email: store.email,
      }
      // console.log(checkoutOptions)
      redirectToCheckout(checkoutOptions)
    } else {
      navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.kayakCount])

  const {
    translate: {
      checkOut: { checkingOut }
    }
  } = useStore()

  return (
    <Container>
      <Box>
        {checkingOut}
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
    </Container>
  )
})

export default Checkout

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`
