import React from 'react'
import styled from 'styled-components'

interface Props {
  x: string
  /** horizontal */
  h?: boolean
}

const Whitespace = ({ x, h }: Props) => {
  if (h) return <CustomDivH x={x} />
  else return <CustomDiv x={x} />
}

export default Whitespace

const CustomDiv = styled.div<{ x: string }>`
  padding-bottom: ${({ x }) => `${x}rem`};
`

const CustomDivH = styled.div<{ x: string }>`
  padding-right: ${({ x }) => `${x}rem`};
`
