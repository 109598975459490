import CurrentLocationMarker from '../Map/CurrentLocationMarker'
import React from 'react'
import ReactDOM from 'react-dom/client'
import locationOff from 'icons/location.svg'
import { GoogleMap, KmlLayer, useLoadScript } from '@react-google-maps/api'
import { IPosition } from 'utils/interfaces'
import { LocationIcon } from '../Map/GoogleMapWrapper'
import { MAP_API_KEY, MAP_CENTER, MAP_OPTIONS } from 'components/ProjectSpecificComponents/Constants/MAP_CONSTANTS'
import { observer } from 'mobx-react-lite'
import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'
interface Props {
  routeKMZ: string
  getLocation: () => void
  currentLocation?: IPosition
}

// Documentation - https://react-google-maps-api-docs.netlify.app/
const RouteGoogleMapWrapper = observer(({ routeKMZ, currentLocation, getLocation }: Props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: MAP_API_KEY
  })

  const store = useStore()

  const POI_ROUTE = store.kayakingRoutes.find(({ difficulty }) => difficulty === 'POI').routeKMZ

  const onLoadMap = (map: any) => {
    const controlButtonDiv = document.createElement('div')
    const anch = ReactDOM.createRoot(controlButtonDiv)
    anch.render(<LocationIcon onClick={getLocation} src={locationOff} width='80%' height='80%' />)
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlButtonDiv)
  }

  if (loadError) return <p>Error loading map</p>
  if (!isLoaded) return <p>Loading map</p>

  return (
    <>
      {isLoaded && (
        <GoogleMap
          id='route-map-container'
          mapContainerStyle={{
            width: '100%',
            height: '60vw',
            minHeight: 200,
            maxHeight: 600
          }}
          center={MAP_CENTER}
          options={{
            ...MAP_OPTIONS,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER
            }
          }}
          onLoad={onLoadMap}
          zoom={13}
        >
          <CurrentLocationMarker position={currentLocation} />

          {POI_ROUTE && (
            <KmlLayer
              url={POI_ROUTE}
              onLoad={() => {}}
              onUnmount={() => {}}
              options={{
                preserveViewport: true
              }}
            />
          )}
          {routeKMZ && (
            <KmlLayer
              url={routeKMZ}
              onLoad={() => {}}
              onUnmount={() => {}}
              options={{
                preserveViewport: true
              }}
            />
          )}
        </GoogleMap>
      )}
    </>
  )
})

export default RouteGoogleMapWrapper
