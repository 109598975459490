import { styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { getCity, texts } from 'utils/utils'
import { useStore } from './ProjectSpecificComponents/Contexts/GlobalStoreContext'

const BookingWarning = observer(() => {
  const {
    translate: {
      findUs: { contactUs, socials }
    }
  } = useStore()

  const { contacts } = texts[getCity()]
  const contactUsParsed = contactUs
    .replace('{phoneHref}', `tel:${contacts.replaceAll(' ', '')}`)
    .replace('{waHref}', `https://wa.me/${contacts.replaceAll(' ', '')}`)
    .replace('{tgHref}', `https://telegram.me/${contacts.replaceAll(' ', '')}`)
    .replace('{phone}', contacts)

  const socialsParsed = socials
    .replace('{fbHref}', 'http://m.me/rigakayaking')
    .replace('{igHref}', 'https://www.instagram.com/riga_kayaking/')

  const __html = `Due to system maintenance,<br/>  
    To plan your kayaking adventure, please ${contactUsParsed.toLocaleLowerCase()}, ${socialsParsed.toLocaleLowerCase()}.`

  return (
    <Box>
      <Attention>Attention!</Attention>

      <WarningBox dangerouslySetInnerHTML={{ __html }} />

      <div />
      <div>Thanks! </div>
      <div>The RigaKayaking.com team</div>
      <div />
    </Box>
  )
})

export default BookingWarning

const Box = styled('div')({
  fontSize: '14px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
})
const Attention = styled('div')({
  fontSize: '18px',
  color: '#FF9966'
})

const WarningBox = styled('label')({
  '& > a': {
    padding: 0
  }
})
