import MediumButton from '../Buttons/MediumButton'
import React from 'react'
import rmSpaces from 'components/reusable/Functions/rmSpaces'
import { ROUTE, scrollToTop } from 'utils/utils'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'

type Props = {
  route?: string | undefined
}
const BookNow = observer(({ route }: Props) => {
  const navigate = useNavigate()

  const bookRoute = () => {
    const routeLabel = route ? `/${rmSpaces(route)}/` : ''
    scrollToTop()
    navigate(`${ROUTE.toBookKayak}${routeLabel}`)
  }
  const {
    translate: {
      buttons: { bookNow }
    }
  } = useStore()

  return (
    <MediumButton onClick={bookRoute} bigRedButton>
      <h3>{bookNow}</h3>
    </MediumButton>
  )
})

export default BookNow
