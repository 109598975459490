/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { RK_OFICIAL_TEL } from 'utils/constants'
import { completeAirtableOrder, sendSms, texts, getCity, ROUTE } from 'utils/utils'
import { useStore } from '../Contexts/GlobalStoreContext'

interface IOrderData {
  isCompleted: boolean
  phone: any
  smsContent: string
}

const AfterCheckout = observer(() => {
  const { bookingId } = useParams()
  const [orderData, setOrderData] = useState<IOrderData | undefined>(undefined)
  const { contacts } = texts[getCity()]
  const navigate = useNavigate()

  // Get booking info from bookingId
  useEffect(() => {
    if (bookingId) {
      const apiKey = 'patju9CJareSBScLs.ca009b7e8c611865abfe97157e715ba1d3d42767e2feaed240730ba2707f7b4c'
      const Airtable = require('airtable')
      const base = new Airtable({apiKey}).base('app9RWF9tUG286xQR')

      base('Bookings').find(bookingId, (err, record) => {
        setOrderData({
          isCompleted: record.get('order_completed'),
          phone: record.get('phone'),
          smsContent: record.get('Res_Summary')
        })
      })
    }
  }, [bookingId])

  //  Send messages after booking is done
  useEffect(() => {
    if (orderData && bookingId) {
      const orderAlreadyCompleted: boolean = orderData.isCompleted === true
      const shouldCompleteOrder: boolean = orderData.isCompleted === undefined

      if (orderAlreadyCompleted) {
        navigate(ROUTE.thanks)
      }

      if (shouldCompleteOrder) {
        finishOrder()
      }
    }
  }, [orderData])

  const finishOrder = async () => {
    const notifyUser = await sendSms({
      phoneNumber: orderData.phone,
      message: weReceivedReservation.replace('{contacts}', contacts)
    })

    if (notifyUser?.error) {
      return navigate(ROUTE.err)
    }

    const notifyAdmin = await sendSms({
      phoneNumber: RK_OFICIAL_TEL,
      message: orderData.smsContent
    });

    // Check if both SMS sends had an error
    // if (notifyAdmin?.error && notifyAdmin2?.error) {
    if (notifyAdmin?.error) {
      return navigate(ROUTE.err);
    }

    completeAirtableOrder(bookingId)
    setTimeout(() => {
      document.location.href = ROUTE.thanks
    }, 1000)
  }

  const {
    translate: {
      checkOut: { finishing, weReceivedReservation }
    }
  } = useStore()

  return (
    <Container>
      <Box>
        {finishing}
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
    </Container>
  )
})

export default AfterCheckout

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`
