import './main.css'
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import concatWithSpaces from 'components/reusable/Functions/concatWithSpaces'
import useScreenType from 'components/reusable/Hooks/useScreenType'

interface Props extends React.ComponentProps<'button'> {
  wide?: boolean
  inverted?: boolean
  semiTransparent?: boolean
  borderless?: boolean
  isActive?: boolean
  isInactive?: boolean
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      wide = false,
      inverted = false,
      disabled = false,
      semiTransparent = false,
      borderless = false,
      isActive,
      isInactive,
      onClick,
      style,
      className,
      ...props
    },
    ref
  ) => {
    const { isMobile } = useScreenType()
    return (
      <CustomButton
        {...props}
        className={concatWithSpaces(
          onClick && !disabled ? 'pointer' : '',
          inverted ? 'inverted' : '',
          wide ? 'wide' : '',
          isActive ? 'active' : '',
          isInactive ? 'inactive' : '',
          className
        )}
        style={{
          borderRadius: isMobile ? '20px' : '15px',
          ...(semiTransparent
            ? {
                color: 'var(--blue)',
                backgroundColor: 'rgba(38, 124, 231, 0.15)', // var(--blue) 15%
                border: '2px solid var(--blue)'
              }
            : {}),
          ...(borderless
            ? {
                color: 'var(--muddy-puddle-gray)',
                backgroundColor: 'var(--white)'
              }
            : {}),
          ...style
        }}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
      />
    )
  }
)

export default Button

const CustomButton = styled.button`
  min-height: 32px;
  padding: 0 16px;

  color: var(--white);
  background-color: var(--main-red);

  border: none;
  outline: none;
  font-size: inherit;

  :hover {
    opacity: 0.8;
  }
`
