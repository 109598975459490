/**
 * A friendly, easy to remember, function that returns baseUrl - "https://www.adress.com" without "/path"
 */

const getUrl = {
    host: window.location.host, // localhost:5001
    base: window.location.origin, // https://localhost:5001/
    full: window.location.href, // full path
}

export default getUrl
