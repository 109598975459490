/* eslint-disable react-hooks/exhaustive-deps */
import RouteCard from './RouteCard'
import styled from 'styled-components'
import useScreenType from 'components/reusable/Hooks/useScreenType'
import { TKayakingRoute } from 'components/ProjectSpecificComponents/Stores/GlobalStore'
import { fetchAllRoutes } from 'utils/utils'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'

const RouteList = observer(() => {
  const store = useStore()
  const { isMobile } = useScreenType()

  useEffect(() => {
    const getRoutes = async () => {
      const data = await fetchAllRoutes()
      store.setKayakingRoutes(data)
    }

    getRoutes()
  }, [])

  return (
    <RouteListContainer isMobile={isMobile}>
      {store.kayakingRoutes.map((route: TKayakingRoute) => (
        <React.Fragment key={route.id}>
          <RouteCard card={route} />
        </React.Fragment>
      ))}
    </RouteListContainer>
  )
})

export default RouteList

const RouteListContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  width: 1000px;
  overflow-x: scroll;
  flex-wrap: nowrap;
  padding-bottom: 50px;
  max-width: ${({ isMobile }) => (isMobile ? '97%' : '90%')};
`
