import React, { forwardRef } from 'react'
import Button from './Button/Button'
import useScreenType from 'components/reusable/Hooks/useScreenType'

interface Props extends React.ComponentProps<'button'> {
  iconColor?: string
  semiTransparent?: boolean
  borderless?: boolean
  children?: React.ReactNode
  isActive?: boolean
  isInactive?: boolean
  bigRedButton?: boolean
}

const MediumButton = forwardRef<HTMLButtonElement, Props>(
  ({ iconColor, style, children, bigRedButton, ...props }, ref) => {
    const { isMobile } = useScreenType()

    const bigRedBtnStyle: React.CSSProperties = bigRedButton ? { ...(isMobile ? bigRedBtnMobile : bigRedBtn) } : {}

    return (
      <Button
        {...props}
        style={{
          height: isMobile ? 20 : 35,
          minWidth: isMobile ? 42 : 55,
          padding: isMobile ? 8 : '0 16px',
          fontSize: 14,
          fontWeight: 300,
          letterSpacing: '.067em',
          cursor: 'pointer',
          maxWidth: '80vw',
          ...bigRedBtnStyle,
          ...style
        }}
        ref={ref}
      >
        {children}
      </Button>
    )
  }
)

export default MediumButton

export const bigRedBtnMobile: React.CSSProperties = {
  fontSize: 20,
  fontWeight: 600,
  height: 45,
  width: 200,
  borderRadius: 18,
  letterSpacing: 'normal',
  padding: '10px 41px'
}

export const bigRedBtn: React.CSSProperties = {
  fontSize: 30,
  fontWeight: 600,
  height: 60,
  width: 300,
  borderRadius: 22,
  letterSpacing: 'normal',
  padding: '10px 41px'
}
