import BookNow from '../OpenedRoute/BookNow'
import GoogleMapWrapper from './GoogleMapWrapper'
import React, { useState } from 'react'
import ToggleInstructions from './ToggleInstructions'
import ToggleMapLayers from './ToggleMapLayers'
import UsePhone from '../OpenedRoute/UsePhone'
import Whitespace from 'components/reusable/Elements/Whitespace'
import styled from 'styled-components'
import { ESection } from 'utils/constants'
import { getCurrentPosition } from 'utils/utils'

const Map = () => {
  const [currentLocation, setCurrentLocation] = useState<any>()

  const getLocation = () => {
    getCurrentPosition((coordinates) => setCurrentLocation(coordinates))
  }

  return (
    <MapContainer id={ESection.MAP}>
      <ToggleMapLayers />
      <Whitespace x='3' />

      <GoogleMapWrapper getLocation={getLocation} currentLocation={currentLocation} />

      <ToggleInstructions />
      <Whitespace x='3' />
      <Instructions>
        <UsePhone getLocation={getLocation} />
        <Whitespace x='3' />
        <BookNow />
      </Instructions>
    </MapContainer>
  )
}

export default Map

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
`
const Instructions = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
