import React, { useState } from 'react'
import img1 from 'gallery/1.jpg'
import img2 from 'gallery/2.jpg'
import img3 from 'gallery/3.jpg'
import img4 from 'gallery/4.jpg'
import img5 from 'gallery/5.jpg'
import img6 from 'gallery/6.jpg'
import img7 from 'gallery/7.jpg'
import img8 from 'gallery/8.jpg'
import img9 from 'gallery/9.jpg'
import img10 from 'gallery/10.jpg'
import img11 from 'gallery/11.jpg'
import img12 from 'gallery/12.jpg'
import img13 from 'gallery/13.jpg'
import img14 from 'gallery/14.jpg'
import img15 from 'gallery/15.jpg'
import img16 from 'gallery/16.jpg'
import img17 from 'gallery/17.jpg'
import img18 from 'gallery/18.jpg'
import img19 from 'gallery/19.jpg'
import img20 from 'gallery/20.jpg'
import styled from 'styled-components'

const Gallery = () => {
  const [image, setImage] = useState<string>()
  const onImageClick = (img: string) => setImage(img)
  const onCloseImg = () => setImage(null)

  if (image) {
    return (
      <>
        <OpenedImage onClick={onCloseImg} className='main_image pointer' src={image} />
        <CloseImage onClick={onCloseImg} className='pointer'>
          Close image
        </CloseImage>
      </>
    )
  }

  return (
    <GalleryRow className='gallery-row'>
      <Img src={img1} onClick={() => onImageClick(img1)} alt='img1' />
      <Img src={img2} onClick={() => onImageClick(img2)} alt='img2' />
      <Img src={img3} onClick={() => onImageClick(img3)} alt='img3' />
      <Img src={img4} onClick={() => onImageClick(img4)} alt='img4' />
      <Img src={img5} onClick={() => onImageClick(img5)} alt='img5' />
      <Img src={img6} onClick={() => onImageClick(img6)} alt='img6' />
      <Img src={img7} onClick={() => onImageClick(img7)} alt='img7' />
      <Img src={img8} onClick={() => onImageClick(img8)} alt='img8' />
      <Img src={img9} onClick={() => onImageClick(img9)} alt='img9' />
      <Img src={img10} onClick={() => onImageClick(img10)} alt='img10' />
      <Img src={img11} onClick={() => onImageClick(img11)} alt='img11' />
      <Img src={img12} onClick={() => onImageClick(img12)} alt='img12' />
      <Img src={img13} onClick={() => onImageClick(img13)} alt='img13' />
      <Img src={img14} onClick={() => onImageClick(img14)} alt='img14' />
      <Img src={img15} onClick={() => onImageClick(img15)} alt='img15' />
      <Img src={img16} onClick={() => onImageClick(img16)} alt='img16' />
      <Img src={img17} onClick={() => onImageClick(img17)} alt='img17' />
      <Img src={img18} onClick={() => onImageClick(img18)} alt='img18' />
      <Img src={img19} onClick={() => onImageClick(img19)} alt='img19' />
      <Img src={img20} onClick={() => onImageClick(img20)} alt='img20' />
    </GalleryRow>
  )
}

export default Gallery

const OpenedImage = styled.img`
  height: 500px;
  width: 90vw;
  border-radius: 20px;
  max-width: 500px;
  box-shadow: 0px 0px 2px 2px black;
`

const GalleryRow = styled.div`
  max-width: 95vw;
  padding-bottom: 40px;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  width: 100vw;
  margin: 0px 20px;
  gap: 20px;
`

const Img = styled.img`
  width: 25%;
  margin: 5px;
  cursor: pointer;
`

const CloseImage = styled.p`
  padding: 5px 20px;
  border-radius: 5px;
  background-color: black;
  color: white;
  position: relative;
  bottom: 50px;
`
