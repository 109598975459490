export const RK_TEL = '+371 20 220 580'
export const DANIELS_PERSONAL_TEL = '+37126628098'
export const RK_OFICIAL_TEL = '+37120220580'
export const MATISS_PERSONAL_TEL = '+37128620303'

export enum ERoute {
  LABEL = 'label',
  KMZ = 'route_kmz',
  DESC = 'desc',
  CARD_IMG = 'card_img',
  COVER_IMG = 'img_url',
  DIFF = 'difficulty',
  KM = 'km',
  FROM = 'length_from',
  TO = 'length_to',
  ADDRESS = 'starting_point_address',
  GEO = 'starting_point_geo_location',
  CITY = 'city',
  PUBLIC = 'public'
}

export enum ESection {
  LANDING = 'section-landing',
  MAP = 'section-map',
  ROUTE_DESC = 'section-route-desc',
  INSTRUCTIONS = 'section-instructions'
}

export const GRID_VIEW = {
  maxRecords: 10000,
  view: 'Grid view'
}

export enum CITY {
  RIGA = 'riga'
}

export enum STRIPE_RK_KAYAKS {
  ONE_SEAT_30 = 'price_1P3KUPDKJtE6oKMjnThCW50E',
  TWO_SEATS_30 = 'price_1P3KUqDKJtE6oKMj0q6EnGRF',
  THREE_SEATS_40 = 'price_1P3KVEDKJtE6oKMjoDHjx2UT'
}

export enum STRIPE_RK_KAYAKS_TEST {
  ONE_SEAT_30 = 'price_1L87SwFoVQ0LirNnLRCGJ3gz',
  TWO_SEATS_30 = 'price_1L87UKFoVQ0LirNnc00A4Z3e',
  THREE_SEATS_40 = 'price_1L87VvFoVQ0LirNnyjtOEEfb'
}

export const options0to10: any = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' }
]
