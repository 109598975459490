import MobileContacts, { FacebookIcon } from 'components/MobileContacts'
import RKLogoHeader from 'components/RKLogoHeader'
import React from 'react'
import styled from 'styled-components'
import useScreenType from './reusable/Hooks/useScreenType'

const CoverPhotoButtons = () => {
  const { isMobile } = useScreenType()

  return (
    <>
      <RKLogoHeader />

      <ContactContainer isMobile={isMobile}>
        <Contacts isMobile={isMobile}>
          <MobileContacts />
          <FacebookIcon />
        </Contacts>
      </ContactContainer>
    </>
  )
}

export default CoverPhotoButtons

const ContactContainer = styled.div<{ isMobile: boolean }>`
  position: absolute;
  width: 33vw;
  top: 50px;
  border-radius: 0px 15px 15px 0px;
  padding: 15px 40px 20px 20px;
  display: flex;
  justify-content: flex-end;
  right: ${({ isMobile }) => (isMobile ? '0' : '10vw')};
`

const Contacts = styled.div<{ isMobile: boolean }>`
  min-height: 100px;
  width: fit-content;
  background-color: black;
  align-items: center;
  border-radius: 20px;
  padding: ${({ isMobile }) => (isMobile ? '10px' : '10px 20px')};
  display: flex;
  gap: 5px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`
