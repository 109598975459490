/* eslint-disable react-hooks/exhaustive-deps */
import MediumButton from '../Elements/Buttons/MediumButton'
import React from 'react'
import rk_white from './../../../icons/rk_white.png'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { texts, getCity } from 'utils/utils'
import { useStore } from '../Contexts/GlobalStoreContext'

const Thanks = observer(() => {
  const goToStart = () => {
    document.location.href = '/'
    return
  }
  const { contacts } = texts[getCity()]
  const store = useStore()

  const {
    translate: {
      buttons: { thanks },
      thanks: { header, awaitConfirmation }
    }
  } = store

  return (
    <ThanksContainer>
      <img src={rk_white} width={250} alt='rk_logo' />
      <h2 dangerouslySetInnerHTML={{ __html: header }} />
      <p>{awaitConfirmation.replace('{phone}', contacts)}</p>

      <MediumButton onClick={goToStart} bigRedButton>
        {thanks}
      </MediumButton>
    </ThanksContainer>
  )
})

export default Thanks

const ThanksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 90vw;
  height: 100vh;
  justify-content: center;
  gap: 30px;
`
