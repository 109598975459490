import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'
import useScreenType from 'components/reusable/Hooks/useScreenType'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

const StaySafe = observer(() => {
  const { isDesktop, isMobile } = useScreenType()
  const {
    translate: { instuctions }
  } = useStore()
  return (
    <StaySafeContainer>
      <Heading>Stay safe!</Heading>

      <InstructionsList isDesktop={isDesktop}>
        {INSTRUCTION_LIST.map(({ iconUrl, key }, index) => {
          return (
            <InstructionContainer key={`inst-${index}`} isDesktop={isDesktop}>
              <Icon src={iconUrl} height={isMobile ? '50px' : '100px'} />
              <Text dangerouslySetInnerHTML={{ __html: instuctions[key] }} />
            </InstructionContainer>
          )
        })}
      </InstructionsList>
    </StaySafeContainer>
  )
})

const INSTRUCTION_LIST = [
  {
    iconUrl:
      'https://firebasestorage.googleapis.com/v0/b/rigakayaking-61916.appspot.com/o/StaySafeIcons%2Ftransport.png?alt=media&token=2b9efa8f-a8c3-49f8-9495-51755dfbacf2',
    key: 'stayClose'
  },
  {
    iconUrl:
      'https://firebasestorage.googleapis.com/v0/b/rigakayaking-61916.appspot.com/o/StaySafeIcons%2Ftransportation.png?alt=media&token=fc0d1040-90c9-45f4-8903-27fd52bec613',
    key: 'paddlesBreak'
  },
  {
    iconUrl:
      'https://firebasestorage.googleapis.com/v0/b/rigakayaking-61916.appspot.com/o/StaySafeIcons%2Fnavigation.png?alt=media&token=3c8a921b-3585-4005-a692-8a96f177589c',
    key: 'safetyVest'
  },
  {
    iconUrl:
      'https://firebasestorage.googleapis.com/v0/b/rigakayaking-61916.appspot.com/o/StaySafeIcons%2Ftransportation_ship.png?alt=media&token=e4454a45-7f42-437c-b7d3-8270ad758602',
    key: 'giveWay'
  },
  {
    iconUrl:
      'https://firebasestorage.googleapis.com/v0/b/rigakayaking-61916.appspot.com/o/StaySafeIcons%2Finterface.png?alt=media&token=2747d915-0d11-4abf-9373-64a624b096aa',
    key: 'watchBothWays'
  },
  {
    iconUrl:
      'https://firebasestorage.googleapis.com/v0/b/rigakayaking-61916.appspot.com/o/StaySafeIcons%2Fgps.png?alt=media&token=c4aa2584-6f18-41f7-9b96-0069109468c7',
    key: 'crossRiver'
  },
  {
    iconUrl:
      'https://firebasestorage.googleapis.com/v0/b/rigakayaking-61916.appspot.com/o/StaySafeIcons%2Fquestion-mark.png?alt=media&token=4c14ba23-315d-42d1-a7bd-3ae3afc1e508',
    key: 'firstTime'
  }
]

export default StaySafe

const StaySafeContainer = styled.div`
  width: 1000px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
`

const Heading = styled.h1`
  font-size: 60px;
  padding-bottom: 50px;
`

const InstructionsList = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ isDesktop }) => (isDesktop ? 'flex-start' : 'center')};
`

const InstructionContainer = styled.div<{ isDesktop: boolean }>`
  display: flex;
  width: ${({ isDesktop }) => (isDesktop ? '400px' : '100%')};
  padding-bottom: ${({ isDesktop }) => (isDesktop ? '70px' : '30px')};

  max-width: 90vw;
  margin-right: ${({ isDesktop }) => (isDesktop ? '100px' : '0px')};
  align-items: center;
`

const Icon = styled.img`
  margin-right: 30px;
`
const Text = styled.p`
  font-size: 25px;
  color: white;
  line-height: 1.4;
`
