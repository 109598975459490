export type T_KMZ_TYPE = 'Easy' | 'Medium' | 'Hard' | 'All' | 'POI'

type T_KMZ_BUTTONS = {
  label: T_KMZ_TYPE
  color: string
}

export const KMZ_BUTTONS: T_KMZ_BUTTONS[] = [
  { label: 'Easy', color: 'var(--Easy)' },
  { label: 'Medium', color: 'var(--Medium)' },
  { label: 'Hard', color: 'var(--Hard)' },
  { label: 'All', color: 'var(--gray)' }
]
