/* eslint-disable react-hooks/exhaustive-deps */
import AddSubKayaks from '../Elements/AddSubKayaks'
import CountrySelect from 'components/CountrySelect'
import ErrorLabel from 'components/ErrorLabel'
import rk_white from './../../../icons/rk_white.png'
import MediumButton from '../Elements/Buttons/MediumButton'
import React, { useEffect, useMemo, useState } from 'react'
import Whitespace from 'components/reusable/Elements/Whitespace'
import getUrl from 'components/reusable/Functions/getUrl'
import rmSpaces from 'components/reusable/Functions/rmSpaces'
import styledComp from 'styled-components'
import useScreenType from 'components/reusable/Hooks/useScreenType'
import { Box, CircularProgress, FormControl, MenuItem, Select, TextField } from '@mui/material'
import { COUNTRY_CODES } from 'utils/countryCode'
import { ICountryType, IDate, TBooking } from 'utils/interfaces'
import { StaticDatePicker, TimePicker } from '@mui/x-date-pickers'
import { TKayakingRoute } from '../Stores/GlobalStore'
import { TRouteName } from './OpenedRoute'
import { observer } from 'mobx-react-lite'
import { styled } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '../Contexts/GlobalStoreContext'
import {
  ROUTE,
  fetchAllRoutes,
  getCity,
  isDateInTwoHours,
  isDateToday,
  isEmailValid,
  isPhoneValid,
  parseDateForAirtable,
  readHoursAndMinutesFromDate,
  scrollToTop,
  texts
} from 'utils/utils'
import BookingWarning from 'components/BookingWarning'

const BookKayakForm = observer(() => {
  const navigate = useNavigate()
  const { isMobile } = useScreenType()
  const ConditionalInputBox = isMobile ? InputBoxV : InputBoxH
  const has50discount = false
  const [customErrors, setCustomErros] = useState<any>({})
  const { register, handleSubmit, watch, setValue } = useForm()
  const store = useStore()
  const { routeName }: TRouteName = useParams()
  const { contacts } = texts[getCity()]

  const {
    translate: { form, errors, buttons }
  } = store

  useEffect(() => {
    const getRoutes = async () => {
      const data = await fetchAllRoutes()
      store.setKayakingRoutes(data)
    }

    getRoutes()
  }, [])

  useEffect(() => {
    const isTestEnv = getUrl.full.includes(ROUTE.testBooking)
    store.setTestMode(isTestEnv)
  }, [])

  const finalKayaks = useMemo(() => {
    const [kayaksOne, kayaksTwo, kayaksThree] = watch(['kayaksOne', 'kayaksTwo', 'kayaksThree'])
    const oneSum = kayaksOne * (has50discount ? 15 : 30)
    const twoSum = kayaksTwo * (has50discount ? 15 : 30)
    const threeSum = kayaksThree * (has50discount ? 20 : 40)

    let count = ``
    if (kayaksOne > 0) count += `1seat: x${kayaksOne}, `
    if (kayaksTwo > 0) count += `2seat: x${kayaksTwo}, `
    if (kayaksThree > 0) count += `3seat: x${kayaksThree},`

    const price = oneSum + twoSum + threeSum
    return { price, count }
  }, [has50discount, watch(['kayaksOne', 'kayaksTwo', 'kayaksThree'])])

  const onLogoClick = () => navigate(ROUTE.home)
  const onBackClick = () => navigate(-1)

  const [date, setDate] = useState<Date>(new Date())
  const [bookingTime, setBookingTime] = useState<IDate>({ from: null, to: null })

  const handleBookingTime = (type: 'from' | 'to') => (date: Date | null) => {
    setBookingTime({ ...bookingTime, [type]: date })
  }

  const blockSubmitForSecond = () => {
    setSubmitDisabled(true)
    setTimeout(() => {
      setSubmitDisabled(false)
    }, 1000)
  }

  const onSubmit = async (data: any) => {
    blockSubmitForSecond()
    var formErrors: any = {}
    setCustomErros({})

    // if(isQuickCheckout) {
    //   // Set bookingTime.from to the current date and time
    //   bookingTime.from = new Date();
    //   // Set bookingTime.to to 4 hours from the current time
    //   bookingTime.to = new Date(new Date().getTime() + 4 * 60 * 60 * 1000);
    // }

    const isTimeUndefined = !bookingTime.from || !bookingTime.to
    const isInTwoHours = isDateToday(date) && isDateInTwoHours(bookingTime.from)

    if (isTimeUndefined) {
      formErrors.bookingTime = errors.timeError
    } else if (isInTwoHours && !isQuickCheckout) {
      formErrors.bookingTime = errors.twoHourError.replace('{contacts}', contacts)
    } 

    if (!data.name) {
      formErrors.name = errors.nameError
    }

    if (await !isEmailValid(data.email)) {
      formErrors.email = errors.emailError
    }

    const fullPhone = country.phone.concat(data.fullPhone)
    if (await !isPhoneValid(fullPhone)) {
      formErrors.fullPhone = errors.phoneError
    }
    if (finalKayaks?.price === 0) {
      formErrors.totalPrice = errors.kayakError
    }

    const hasErrors = Object.keys(formErrors).length > 0

    if (hasErrors) {
      scrollToTop()
      setCustomErros(formErrors)
      return
    }

    const route_name = store.kayakingRoutes.find(({ label = '' }: TKayakingRoute) => rmSpaces(label.toLowerCase()) === data.routeName)?.label ?? data.routeName;

    const readyData: TBooking = {
      domain: getCity(),
      booking_date: parseDateForAirtable(date),
      start_time: readHoursAndMinutesFromDate(bookingTime.from),
      end_time: readHoursAndMinutesFromDate(bookingTime.to),
      route_name: route_name,
      one_seat_kayak: Number(data.kayaksOne),
      two_seat_kayak: Number(data.kayaksTwo),
      three_seat_kayak: Number(data.kayaksThree),
      full_name: data.name,
      email: data.email,
      phone: fullPhone,
      rent_price: finalKayaks?.price
    }

    // console.log(readyData);
    submitBooking(readyData);
  }

  const [country, setCountry] = useState<ICountryType>(COUNTRY_CODES[117])
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false)

  const submitBooking = async (data: TBooking) => {
    const { one_seat_kayak, two_seat_kayak, three_seat_kayak } = data
    const kayakCount = { one_seat_kayak, two_seat_kayak, three_seat_kayak }
    store.setKayakCount(kayakCount)
    store.setNumber(data.phone);
    store.setEmail(data.email);

    const apiKey = 'patju9CJareSBScLs.ca009b7e8c611865abfe97157e715ba1d3d42767e2feaed240730ba2707f7b4c'
    const Airtable = require('airtable')
    const base = new Airtable({ apiKey }).base('app9RWF9tUG286xQR')
    const rec = [{ fields: data }]
      
    base('Bookings').create(rec, async (err, records) => {
      const newRecordId = records[0].getId()
      navigate(`${ROUTE.checkout}/${newRecordId}`)
    })
  }

  const bookingErrHasNumber = customErrors?.bookingTime?.includes('call')

  const isWinterRoute = routeName === 'winter'
  const winterEndDate = new Date('2023-12-15T00:00:00')
  const queryParams = new URLSearchParams(window.location.search);
  const isQuickCheckout = queryParams.has('lmr');
  const isWarningShown = false
  // store.isTestMode ? false : !isWinterRoute && date <= winterEndDate
  // console.log(isQuickCheckout);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BookFormContainer>
        <Header>
          <div onClick={onLogoClick} className='pointer'>
            <img src={rk_white} height={30} width={'auto'} alt='rk_logo' />
          </div>
          <MediumButton onClick={onBackClick} style={backBtnStyle}>
            {buttons.back}
          </MediumButton>
        </Header>
        <Divider />
        {isWarningShown && (
          <>
            <BookingWarning />
            <Divider />
          </>
        )}
        <StaticDatePicker
          value={date}
          onChange={(date) => setDate(date || new Date())}
          displayStaticWrapperAs='desktop'
          label='Week picker'
          renderInput={(params) => <TextField {...params} />}
          inputFormat="'Week of' MMM d"
          minDate={new Date()}
          shouldDisableDate={(date) => {
          // Create dates for the days you want to disable
          const disableDates = [
            // new Date(2024, 5, 23), // Remember, months are 0-indexed in JavaScript Dates (0 = January, 5 = June)
            // new Date(2024, 5, 24),
          ];

          // Check if the current date matches any of the dates to disable
          return disableDates.some(disabledDate =>
            date.getDate() === disabledDate.getDate() &&
            date.getMonth() === disabledDate.getMonth() &&
            date.getFullYear() === disabledDate.getFullYear()
          );
        }}
        />
         {/* ) : null} */}
        <ErrorLabel errorText={customErrors.bookingTime} href={bookingErrHasNumber && 'tel:+37120220580'} />

        <ConditionalInputBox>
          <TimeBox>
            <Label>{form.startTime}</Label>
            <TimePicker
              ampm={false}
              minutesStep={15}
              onChange={handleBookingTime('from')}
              minTime={isDateToday(date) ? new Date() : undefined}
              value={bookingTime?.from}
              renderInput={(params) => <StyledInput {...params} size='small' />}
            />
          </TimeBox>

          <TimeBox style={{ opacity: bookingTime?.from ? 1 : 0.5 }}>
            <Label>{form.finishTime}</Label>
            <TimePicker
              ampm={false}
              minutesStep={15}
              disabled={!bookingTime?.from}
              minTime={bookingTime?.from || undefined}
              onChange={handleBookingTime('to')}
              value={bookingTime?.to}
              renderInput={(params) => <StyledInput {...params} size='small' />}
            />
          </TimeBox>
        </ConditionalInputBox>

        <InputBoxV>
          <Label>{form.route}</Label>
          <StyledSelect
            {...register('routeName')}
            size='small'
            // disabled={isWinterRoute}
            defaultValue={isQuickCheckout || !routeName ? 'customexpedition' : rmSpaces(routeName.toLowerCase())}
          >
            {store.kayakingRoutes.map(({ label }: TKayakingRoute) => {
              if (label)
                return (
                  <MenuItem key={label} value={rmSpaces(label.toLowerCase())}>
                    {label}
                  </MenuItem>
                )
            })}
          </StyledSelect>
        </InputBoxV>

        <InputBoxH id='two-seat'>
          <Label> {form.twoSeated.replace('{price}', has50discount ? `15` : `30`)} </Label>
          <AddSubKayaks name='kayaksTwo' register={register} onValueChange={setValue} />
        </InputBoxH>
        <InputBoxH id='three-seat'>
          <Label>{form.threeSeated.replace('{price}', has50discount ? `20` : `40`)} </Label>
          <AddSubKayaks name='kayaksThree' register={register} onValueChange={setValue} />
        </InputBoxH>
        <InputBoxH id='one-seat'>
          <Label>{form.singleSeated.replace('{price}', has50discount ? `15` : `30`)} </Label>
          <AddSubKayaks
            name='kayaksOne'
            register={register}
            onValueChange={(name: any, value: any) => setValue(name, value)}
          />
        </InputBoxH>
        <ErrorLabel errorText={customErrors.name} />
        <InputBoxV>
          <Label>{form.name}</Label>
          <StyledInput {...register('name')} size='small' />
        </InputBoxV>
        <ErrorLabel errorText={customErrors.email} />
        <InputBoxV>
          <Label>{form.email}</Label>
          <StyledInput {...register('email')} size='small' />
        </InputBoxV>
        <ErrorLabel errorText={customErrors.fullPhone} />
        <InputBoxV>
          <Label>{form.phone}</Label>
          <Box flexDirection='row' display='flex'>
            <CountrySelect value={country} onChange={(country: ICountryType) => setCountry(country)} />
            <StyledInput
              {...register('fullPhone')}
              sx={{ borderRadius: '0px 6px 6px 0px', borderLeft: 0 }}
              size='small'
              placeholder={form.phonePlaceholder}
            />
          </Box>
        </InputBoxV>
        {/* <InputBoxV>
            <Label>Gift Card</Label>
            <StyledInput {...register('giftCard')} size="small" />
          </InputBoxV> */}
        <Whitespace x='0.5' />
        <ErrorLabel errorText={customErrors.totalPrice} />
        {finalKayaks?.price > 0 && (
          <InputBoxH>
            <p>{form.totalPrice}</p>
            <p>{`${finalKayaks?.price} Eur`}</p>
          </InputBoxH>
        )}
        <Whitespace x='0.5' />
        <Warning>
            Have a discount code? You can apply it in the next step!
        </Warning>
        <Center>
          <MediumButton type='submit' disabled={isSubmitDisabled || isWarningShown} style={{ width: 200 }}>
            {isSubmitDisabled ? <CircularProgress color='inherit' size={15} /> : <b>{buttons.letsGo}</b>}
          </MediumButton>
        </Center>
        {isWarningShown ? <BookingWarning /> : <Warning>{form.willGetSms}</Warning>}
        <Warning>{form.footer}</Warning>
      </BookFormContainer>
    </form>
  )
})

export default BookKayakForm

const BookFormContainer = styled('div')({
  width: '500px',
  height: '100%',
  maxWidth: '90vw',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '30px',
  paddingBottom: '30px',
  gap: '15px'
})

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '15px'
})

const Divider = styled('div')({
  borderBottom: '1px solid gray',
  width: '100%',
  height: '1px'
})

const backBtnStyle = {
  color: 'black',
  backgroundColor: 'gray',
  width: 100
}

const TimeBox = styledComp.div`
	display: flex;
	flex-direction: column;
	width: 100%;
    gap: 10px;

	span > input {
		height: 38px;
		padding: 0 16px;
		background-color: var(--black);
		outline-color: var(--white);
		border-radius: 6px;
		border: 2px solid var(--gray-c2);

		outline: 0ch !important;
		color: inherit;
		font-family: inherit;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.6;
		text-transform: none;
		word-wrap: normal;

		transition: 0.2s var(--default-slide-transition);
		transition-property: border-color;
	}
`

const Label = styledComp.label`
    min-width: 150px;
`

const InputBoxH = styled(FormControl)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignContent: 'center',
  gap: '10px'
})

const InputBoxV = styled(FormControl)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
})

export const StyledSelect = styled(Select)({
  border: '2px solid var(--gray-c2)',
  outline: 'none',
  width: '100%',
  color: 'white',
  background: 'black !important',

  '& .MuiInputBase-inputSizeSmall': {
    paddingTop: 5,
    paddingBottom: 3
  }
})

const StyledInput = styled(TextField)({
  border: '2px solid var(--gray-c2)',
  outline: 'none',
  width: '100%',
  color: 'white !important',
  borderRadius: '6px',
  background: 'black !important',

  '& .MuiInputBase-root': {
    background: 'black !important'
  },
  '& .MuiInputBase-input': {
    background: 'black !important',
    color: 'white'
  },
  '& .MuiInputBase-inputSizeSmall': {
    paddingTop: 5,
    paddingBottom: 3
  },

  '& .MuiInputBase-input::placeholder': {
    color: 'white'
  }
})

const Warning = styled('label')({
  textAlign: 'center',
  fontSize: '14px'
})

const Center = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
})
