import BG_IMG from 'gallery/web-cover.jpg'
import CoverPhotoButtons from 'components/CoverPhotoButtons'
import MediumButton from './Buttons/MediumButton'
import React from 'react'
import styled from 'styled-components'
import useScreenType from 'components/reusable/Hooks/useScreenType'
import { ESection } from 'utils/constants'
import { ROUTE, scrollToId } from 'utils/utils'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../Contexts/GlobalStoreContext'

const Cover = observer(() => {
  const { isMobile } = useScreenType()
  const navigate = useNavigate()

  const seeRoutes = () => scrollToId('section-route-desc')

  const bookKayak = () => navigate(`${ROUTE.toBookKayak}`)

  const {
    translate: { buttons }
  } = useStore()

  return (
    <CoverContainer id={ESection.LANDING} bgImg={BG_IMG} className='main_image'>
      <CoverPhotoButtons />

      <CoverButtons isMobile={isMobile}>
        <MediumButton onClick={seeRoutes} bigRedButton>
          {buttons.seeRoutes}
        </MediumButton>

        <MediumButton onClick={bookKayak} bigRedButton>
          {buttons.bookKayak}
        </MediumButton>
      </CoverButtons>
    </CoverContainer>
  )
})

export default Cover

const CoverContainer = styled.div<{ bgImg: string }>`
  display: flex;
  flex-dection: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  background-position: bottom;
  background-image: ${({ bgImg }) => `url(${bgImg})`};
`

const CoverButtons = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: flex-end;
  flex-direction: column;
  gap: 1rem;
  height: ${({ isMobile }) => (isMobile ? '60%' : '60%')};
`
