import Whitespace from 'components/reusable/Elements/Whitespace'
import Description from './Description'
import RouteInfo from './RouteInfo'
import RouteList from './RouteList'
import styled from 'styled-components'
import { ESection } from 'utils/constants'

const RoutesDesc = () => {
  return (
    <RoutesDescContainer id={ESection.ROUTE_DESC}>
      <Whitespace x='3' />
      <Description />
      <Whitespace x='4' />
      <RouteList />
      <Whitespace x='3' />
      <RouteInfo />
      <Whitespace x='3' />
    </RoutesDescContainer>
  )
}

export default RoutesDesc

const RoutesDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 90%;
`
