import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'
import Whitespace from 'components/reusable/Elements/Whitespace'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const Description = observer(() => {
  const {
    translate: {
      routes: { header, description }
    }
  } = useStore()

  return (
    <DescContainer>
      <Whitespace x='2' />
      <h1>{header}</h1>
      <Whitespace x='4' />
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </DescContainer>
  )
})

export default Description

const DescContainer = styled.div`
  width: 1000px;
  max-width: 90vw;
`
