import React from 'react'

type Props = { isTestMode: boolean }

const TestModeBar = ({ isTestMode }: Props) => {
  if (!isTestMode) return null

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: 20,
        left: 0,
        background: 'red',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000
      }}
    >
      YOU'RE IN TEST MODE
    </div>
  )
}

export default TestModeBar
