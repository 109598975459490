import AfterCheckout from 'components/ProjectSpecificComponents/Elements/AfterCheckout'
import BookKayakForm from 'components/ProjectSpecificComponents/Pages/BookKayakForm'
import Checkout from './components/ProjectSpecificComponents/Elements/Checkout'
import Err from 'components/ProjectSpecificComponents/Pages/Err'
import GlobalOverlayLoader from './components/reusable/Elements/Loader/GlobalOverlayLoader'
import LandingRiga from 'components/ProjectSpecificComponents/Pages/Landing'
import OpenedRoute from 'components/ProjectSpecificComponents/Pages/OpenedRoute'
import React from 'react'
import TestModeBar from 'components/TestModeBar'
import Thanks from 'components/ProjectSpecificComponents/Pages/Thanks'
import styled from 'styled-components'
import { ROUTE } from 'utils/utils'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from './components/ProjectSpecificComponents/Contexts/GlobalStoreContext'

export const App = observer(() => {
  const store = useStore()

  return (
    <Router>
      <GlobalOverlayLoader active={store.isLoaderOverlayOpen} />
      <TestModeBar isTestMode={store.isTestMode} />
      <AppContainer id='landing-page'>
        <Routes>
          <Route element={<LandingRiga />} path={ROUTE.home} />
          <Route element={<OpenedRoute />} path={ROUTE.card} />

          {[ROUTE.bookKayak, ROUTE.toBookKayak, ROUTE.testBooking].map((path, key) => (
            <Route {...{ path, key }} element={<BookKayakForm />} />
          ))}
          {/* contacts phone for each city is defined in utils.ts/texts */}
          <Route element={<Thanks />} path={`${ROUTE.thanks}`} />
          <Route element={<Checkout />} path={`${ROUTE.checkout}/:bookingId`} />
          <Route element={<AfterCheckout />} path={`${ROUTE.pay}/:bookingId`} />
          <Route element={<Err />} path={ROUTE.err} />
        </Routes>
      </AppContainer>
    </Router>
  )
})

export default App

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
`
