import React from 'react'
import { Marker } from '@react-google-maps/api'
import { IPosition } from 'utils/interfaces'

type Props = {
  position?: IPosition
}

const CurrentLocationMarker = ({ position }: Props) => {
  if (!position) return

  return (
    <Marker
      icon={{
        path: google.maps.SymbolPath.CIRCLE,
        strokeColor: '#fff',
        strokeWeight: 4,
        fillColor: '#1A73E8',
        fillOpacity: 1,
        scale: 9
      }}
      draggable={false}
      position={position}
    />
  )
}

export default CurrentLocationMarker
