import styledComp from 'styled-components'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { MenuItem } from '@mui/material'
import { StyledSelect } from '../Pages/BookKayakForm'
import { options0to10 } from 'utils/constants'
import { useState } from 'react'

type Props = {
  register: any
  name: string
  onValueChange: (name: string, value: any) => void
}

const AddSubKayaks = ({ register, name, onValueChange }: Props) => {
  const [value, setValue] = useState<any>(0)

  const _handleKayakCount = (action: 'sub' | 'add') => {
    const oldVal = Number(value)
    const newVal = action === 'sub' ? (oldVal > 0 ? oldVal - 1 : 0) : oldVal < 10 ? oldVal + 1 : 10

    setValue(newVal)
    onValueChange(name, newVal)
  }

  const onChange = (e: any) => {
    const { value } = e.target
    onValueChange(name, value)
    setValue(value)
  }

  return (
    <AddSubContainer>
      <KeyboardArrowLeft onClick={() => _handleKayakCount('sub')} sx={{ cursor: 'pointer' }} />
      <StyledSelect
        {...register(name)}
        defaultValue={0}
        id='kayak-count'
        size='small'
        value={value}
        onChange={onChange}
        sx={{
          '& .MuiSelect-select': {
            display: 'flex',
            justifyContent: 'center',
            padding: '4px 10px 2px 10px !important',
            width: 20
          },
          '& .MuiSvgIcon-root': {
            display: 'none'
          }
        }}
      >
        {options0to10.map(({ value, label }, index: number) => (
          <MenuItem key={`${index}-${name}`} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
      <KeyboardArrowRight onClick={() => _handleKayakCount('add')} sx={{ cursor: 'pointer' }} />
    </AddSubContainer>
  )
}

export default AddSubKayaks

const AddSubContainer = styledComp.div`
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: center;
`
