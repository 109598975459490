import React from 'react'
import styled from 'styled-components'
import laivinieks from 'icons/laiv.png'
import rtu from 'icons/rtu.png'
import lnta from 'icons/lnta.png'
import c2d from 'icons/c2d.png'

import rk_white from 'icons/rk_white.png'
import ms_white from 'icons/ms_white.png'

import useScreenType from 'components/reusable/Hooks/useScreenType'

const Sponsors = () => {
  const { isMobile } = useScreenType()
  return (
    <>
      <SponsorsRow>
        <img src={rk_white} width='auto' height={isMobile ? '20px' : '40px'} alt='ms-logo' />

        <a target='_blank' rel='noreferrer' href='https://www.letshike.co/'>
          <img src={ms_white} width='auto' height={isMobile ? '40px' : '80px'} alt='ms-logo' />
        </a>

        <img src={laivinieks} width='auto' height={isMobile ? '40px' : '80px'} alt='laivinieks-logo' />
      </SponsorsRow>
      <SponsorsRow>
        <a target='_blank' rel='noreferrer' href='https://dabasturisms.lv/'>
          <img src={lnta} width='auto' height={isMobile ? '20px' : '40px'} alt='lnta-logo' />
        </a>

        <a target='_blank' rel='noreferrer' href='https://www.rtu.lv/'>
          <img src={rtu} width='auto' height={isMobile ? '40px' : '70px'} alt='rtu-logo' />
        </a>

        {/* <a target='_blank' rel='noreferrer' href=''> */}
        <img src={c2d} width='auto' height={isMobile ? '20px' : '40px'} alt='c2d-logo' />
        {/* </a> */}
      </SponsorsRow>
    </>
  )
}

export default Sponsors

const SponsorsRow = styled.div`
  width: 90vw;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;

  align-items: center;

  div {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
