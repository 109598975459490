import { useMediaQuery } from "react-responsive"

/**
 * Returns screen type from devices screen width
 */
export default function useScreenType() {
	const is3Cols = useMediaQuery({ minWidth: SCREEN_MIN_WIDTHS.DESKTOP })
	const is2Cols = useMediaQuery({ minWidth: SCREEN_MIN_WIDTHS.TABLET })
	const is1Cols = useMediaQuery({ minWidth: SCREEN_MIN_WIDTHS.SMALL_TABLET })

	let screenType: TScrenType
	let isDesktop = false
	let isTablet = false
	let isSmallTablet = false
	let isMobile = false

	if (is3Cols) {
		screenType = "3-cols" // Desktop
		isDesktop = true
	} else if (is2Cols) {
		screenType = "2-cols" // Tablet
		isTablet = true
	} else if (is1Cols) {
		screenType = "1-cols" // Small-tablet
		isSmallTablet = true
	} else {
		screenType = "phone" // Phone
		isMobile = true
	}

	return {
		screenType,
		isDesktop,
		isTablet,
		isSmallTablet,
		isMobile,
	}
}

export const SCREEN_MIN_WIDTHS = {
	DESKTOP: 1440,
	TABLET: 1265,
	SMALL_TABLET: 700,
	MOBILE: 320,
} as const

export type TScreenWidthPx = typeof SCREEN_MIN_WIDTHS[keyof typeof SCREEN_MIN_WIDTHS]
export type TScrenType = "3-cols" | "2-cols" | "1-cols" | "phone"
