import MediumButton from '../Buttons/MediumButton'
import React from 'react'
import styled from 'styled-components'
import useScreenType from 'components/reusable/Hooks/useScreenType'
import { observer } from 'mobx-react'
import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'
import { KMZ_BUTTONS, T_KMZ_TYPE } from './KMZ_LAYERS'

const ToggleMapLayers = observer(() => {
  const kmzLayerBtnClick = (key: T_KMZ_TYPE) => store.filterKMZ_KEYS(key)
  const store = useStore()
  const { isMobile } = useScreenType()
  const {
    translate: { buttons }
  } = store

  return (
    <ToggleContainer id='map-buttons'>
      <ButtonsList>
        {KMZ_BUTTONS.map(({ label, color }) => {
          const style = {
            marginLeft: isMobile ? 2 : 10,
            marginRight: isMobile ? 2 : 10,
            marginTop: 2,
            fontSize: isMobile ? 14 : 18,
            width: '100%',
            backgroundColor: store.activeKMZ_KEYS.includes(label) ? color : '#3c3c3c',
            borderRadius: isMobile ? 20 : 50,
            height: isMobile ? 20 : 40,
            fontWeight: 800
          }

          return (
            <MediumButton onClick={() => kmzLayerBtnClick(label)} key={label} {...{ style }}>
              {buttons[label.toLocaleLowerCase()]}
            </MediumButton>
          )
        })}
      </ButtonsList>
    </ToggleContainer>
  )
})

export default ToggleMapLayers

const ToggleContainer = styled.div`
  width: inherit !important;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  top: 10px;
  font-size: 10px;
`

const ButtonsList = styled.div`
  background-color: black;
  padding: 10px;
  border-radius: 20px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  width: 1000px;
  max-width: 100vw;
`
