import * as C from './constants'
import getUrl from 'components/reusable/Functions/getUrl'
import rmSpaces from 'components/reusable/Functions/rmSpaces'
import { ICheckoutItem, ISms, TBooking } from './interfaces'
import { RK_TEL, ERoute, GRID_VIEW } from './constants'
import { TKayakingRoute } from 'components/ProjectSpecificComponents/Stores/GlobalStore'
import { createRoot } from 'react-dom/client'
import { httpsCallable } from 'firebase/functions'
import { functions } from './../firebase'

export const getCity = () => {
  const url = getUrl.host
  switch (true) {
    case url.startsWith(C.CITY.RIGA):
      return C.CITY.RIGA
    default:
      return C.CITY.RIGA
  }
}

export const texts = {
  [C.CITY.RIGA]: {
    contacts: RK_TEL
  }
}

export const reachUrl = (x: any) => {
  try {
    return x[0].url
  } catch (err) {
    return ''
  }
}

export const readHoursAndMinutesFromDate = (date: Date | null) => {
  if (!date) return ''
  const hour = date.getHours()
  const addZeroBeforeHours = hour.toString().length === 1
  const hours = addZeroBeforeHours ? `0${hour}` : hour
  const minutes = date.getMinutes() === 0 ? '00' : date.getMinutes()
  return `${hours}:${minutes}`
}

export const isDateToday = (date: Date | null) => {
  if (!date) return false
  return date.toDateString() === new Date().toDateString()
}

export const isDateInTwoHours = (date: Date | null) => {
  if (!date) return false
  const now = new Date()
  const twoHours = new Date(now.getTime() + 2 * 60 * 60 * 1000)
  return date.getTime() < twoHours.getTime()
}

export const isEmailValid = (email: any) => {
  if (!email) return false
  const isValid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  return isValid
}

export const isPhoneValid = (phone: any) => {
  if (!phone) return false
  if (!phone.startsWith('+')) return false
  const withoutSpaces: string = rmSpaces(phone) ?? ''
  if (withoutSpaces.length < 10) return false
  return true
}

export const parseDateToMMDDYYYY = (date: Date | null) => {
  if (!date) return ''
  const yyyy = date.getFullYear()
  let mm = String(date.getMonth() + 1)
  let dd = String(date.getDate())

  if (dd.length === 1) dd = '0' + dd
  if (mm.length === 1) mm = '0' + mm

  return dd + '/' + mm + '/' + yyyy
}
/** (yyyy-MM-dd) */
export const parseDateForAirtable = (date: Date | null) => {
  if (!date) return ''
  const yyyy = date.getFullYear()
  let mm = String(date.getMonth() + 1)
  let dd = String(date.getDate())

  if (dd.length === 1) dd = '0' + dd
  if (mm.length === 1) mm = '0' + mm

  return yyyy + '-' + mm + '-' + dd
}

export const ROUTE = {
  home: '/',
  err: '/404',
  thanks: '/thanks',
  toBookKayak: '/bookKayak',
  bookKayak: '/bookKayak/:routeName',
  testBooking: '/bookKayak/test',
  fastBooking: '/bookKayak/:fastBooking',
  checkout: '/checkout',
  pay: '/pay',
  toCard: '/route',
  card: '/route/:routeName'
}

const _isTestMode: boolean = getUrl.full.includes(ROUTE.testBooking)

export const getLineItems = ({
  one_seat_kayak,
  two_seat_kayak,
  three_seat_kayak
}: Partial<TBooking>): ICheckoutItem[] => {
  const KAYAKS = _isTestMode ? C.STRIPE_RK_KAYAKS_TEST : C.STRIPE_RK_KAYAKS
  const { ONE_SEAT_30, TWO_SEATS_30, THREE_SEATS_40 } = KAYAKS
  const preparedItems: ICheckoutItem[] = [
    {
      price: ONE_SEAT_30,
      quantity: one_seat_kayak
    },
    {
      price: TWO_SEATS_30,
      quantity: two_seat_kayak
    },
    {
      price: THREE_SEATS_40,
      quantity: three_seat_kayak
    }
  ]

  const rmIfZero = preparedItems.filter(({ quantity }) => quantity > 0)
  return rmIfZero
}

export const sendSms = async ({ phoneNumber, message }: ISms) => {
  const SendSMS = httpsCallable(functions, 'SendSmsFromBe')
  try {
    await SendSMS({
      phoneNumber,
      message
    })

    return null
  } catch (error) {
    return { error }
  }
}

export const getRouteLength = (lengthFrom: number | null, lengthTo: number) => {
  const from = `${lengthFrom} - `
  const to = `${lengthTo}h`
  return lengthFrom ? ''.concat(from, to) : to
}

export const fetchAllRoutes = async () => {
  const apiKey = 'patju9CJareSBScLs.ca009b7e8c611865abfe97157e715ba1d3d42767e2feaed240730ba2707f7b4c'
  const Airtable = require('airtable')
  const base = new Airtable({ apiKey }).base('app0dnp9Oaak60Xvv')

  var routes: TKayakingRoute[] = []
  const collectRouteRecords = (records: any, fetchNextPage: () => void) => {
    records.forEach((record: any) => {
      const singleRoute: TKayakingRoute = {
        id: record.get(ERoute.LABEL),
        // ROUTE CARD
        lengthFrom: record.get(ERoute.FROM),
        lengthTo: record.get(ERoute.TO),
        label: record.get(ERoute.LABEL),
        cardImgUrl: reachUrl(record.get(ERoute.CARD_IMG)),
        difficulty: record.get(ERoute.DIFF),
        // OPENED ROUTE
        routeKMZ: reachUrl(record.get(ERoute.KMZ)),
        desc: record.get(ERoute.DESC),
        imgUrl: reachUrl(record.get(ERoute.COVER_IMG)),
        startingPointKey: 'BD',
        km: record.get(ERoute.KM),
        isPublic: record.get(ERoute.PUBLIC)
      }

      if (singleRoute.isPublic) {
        routes.push(singleRoute)
      }
    })
    fetchNextPage()
  }
  await base('Routes').select(GRID_VIEW).eachPage(collectRouteRecords)
  return routes
}

export const completeAirtableOrder = (bookingId) => {
  const apiKey = 'patju9CJareSBScLs.ca009b7e8c611865abfe97157e715ba1d3d42767e2feaed240730ba2707f7b4c'
  const Airtable = require('airtable')
  const base = new Airtable({ apiKey }).base('app9RWF9tUG286xQR')
  const fields = {
    order_completed: true
  }
  const rec = [{ id: bookingId, fields }]
  base('Bookings').update(rec)
}

export const isBookingCompleted = (bookingId: string, store: any) => {
  const apiKey = 'patju9CJareSBScLs.ca009b7e8c611865abfe97157e715ba1d3d42767e2feaed240730ba2707f7b4c'
  const Airtable = require('airtable')
  const base = new Airtable({ apiKey }).base('app9RWF9tUG286xQR')

  base('Bookings').find(bookingId, (err, record) => {
    store.setOrderCompleted(record.get('order_completed'))
    store.setUserPhoneNumber(record.get('phone'))
    store.setSmsContent(record.get('Res_Summary'))
  })
}

export const scrollToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

export const scrollToId = (id) => {
  const scrollEl = document.getElementById(id)
  if (scrollEl) scrollEl.scrollIntoView({ behavior: 'smooth' })
}

export const formatDate = (date: any) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return `${day}/${month}/${year}`
}
export const isBefore2Hours = (startTime: any, date: any) => {
  const isToday = new Date(date).getDay() == new Date().getDay()
  // Ja nav šodien, tad vienalga
  if (!isToday) return false

  // Viss sekojošais ir par šodienu
  const hours = Number(String(startTime).slice(0, startTime.indexOf(':'))) * 60
  const minutes = Number(String(startTime).slice(startTime.indexOf(':') + 1)) + hours
  const nowHours = new Date().getHours() * 60
  const nowMinutes = new Date().getMinutes() + nowHours
  const timeGap = minutes - nowMinutes

  // Ja laiks ir pagātnē, tad kļūda
  if (timeGap < 0) return true

  // Vai ir tuvak par 2 h?
  return timeGap < 120 ? true : false
}

export const getCurrentPosition = (callBackFn) => {
  navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
    const coordinates = { lat, lng }
    callBackFn(coordinates)
  })
}

export const replaceEl = (id: string, node: any) => {
  const el = document.getElementById(id)
  const root = createRoot(el)
  root.render(node)
}

export const transientProps = {
  shouldForwardProp: (propName) => !propName.startsWith('$')
}
