import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import { texts, getCity } from 'utils/utils'
import { useStore } from '../Contexts/GlobalStoreContext'

const FindUsHere = observer(() => {
  const { contacts } = texts[getCity()]
  const {
    translate: {
      findUs: { header, howToGetHere, questions, contactUs, socials }
    }
  } = useStore()

  const contactUsParsed = contactUs
    .replace('{phoneHref}', `tel:${contacts.replaceAll(' ', '')}`)
    .replace('{waHref}', `https://wa.me/${contacts.replaceAll(' ', '')}`)
    .replace('{tgHref}', `https://telegram.me/${contacts.replaceAll(' ', '')}`)
    .replace('{phone}', contacts)

  const socialsParsed = socials
    .replace('{fbHref}', 'http://m.me/rigakayaking')
    .replace('{igHref}', 'https://www.instagram.com/riga_kayaking/')

  return (
    <FindUsHereContainer>
      <h1>{header}</h1>

      <iframe
        className='embed_google_map'
        title='rent-location'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5052.256866152184!2d24.0824324035114!3d56.94580819543052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eed158582815f5%3A0x9d41248772359b91!2sRiga%20Kayaking!5e1!3m2!1sen!2slv!4v1657259906296!5m2!1sen!2slv'
        width='100%'
        height='300'
        style={{ border: 0 }}
        loading='lazy'
      />

      <p dangerouslySetInnerHTML={{ __html: howToGetHere }} />

      <div>
        <p>{questions}</p>
        <p dangerouslySetInnerHTML={{ __html: contactUsParsed }} />
        <p dangerouslySetInnerHTML={{ __html: socialsParsed }} />
      </div>
    </FindUsHereContainer>
  )
})

export default FindUsHere

const FindUsHereContainer = styled.div`
  margin-top: 80px;
  width: 1000px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  gap: 40px;
`
