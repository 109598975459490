import React from 'react'
import RoutesDesc from '../Elements/RouteDesc/RoutesDesc'
import Map from '../Elements/Map/Map'
import StaySafe from '../Elements/OpenedRoute/StaySafe'
import Footer from '../Elements/Footer'
import Kayaks from '../Elements/Kayaks/Kayaks'
import Gallery from '../Elements/Gallery'
import FindUsHere from '../Elements/FindUsHere'
import Sponsors from '../Elements/Sponsors'
import Cover from '../Elements/Cover'
import Whitespace from 'components/reusable/Elements/Whitespace'

const LandingRiga = () => {
  return (
    <>
      <Cover />
      <RoutesDesc />
      <Map />
      <Whitespace x='3' />
      <StaySafe />
      <Whitespace x='3' />
      <Kayaks />
      <Gallery />
      <FindUsHere />
      <Whitespace x='5' />
      <Sponsors />
      <Whitespace x='3' />
      <Footer />
      <Whitespace x='2' />
    </>
  )
}

export default LandingRiga
