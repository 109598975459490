export interface TBooking {
  domain: string
  full_name: string
  email: string
  phone: string
  booking_date: string
  one_seat_kayak: number
  two_seat_kayak: number
  three_seat_kayak: number
  route_name: string
  start_time: string
  end_time: string
  rent_price: number
}

export interface ICheckoutItem {
  price: string
  quantity: number
}
export interface ICountryType {
  code: string
  label: string
  phone: string
  suggested?: boolean
}

export enum ECheckoutMode {
  PAYMENT = 'payment',
  SUBSCRIPTION = 'subscription'
}

export interface ICheckoutOptions {
  lineItems: ICheckoutItem[]
  mode: 'payment'
  successUrl: string
  cancelUrl: string
  customer_email: string
}

export interface ISms {
  phoneNumber: string
  message: string
}

export interface IDate {
  from: Date | null
  to: Date | null
}

export interface IPosition {
  lat: number
  lng: number
}
