import React from 'react'
import styled from 'styled-components'

type Props = {
  errorText?: any
  href?: string
}

const ErrorLabel = ({ errorText, href }: Props) => {
  if (!errorText) return null

  return (
    <Label {...(href && { href })} target='_blank'>
      <div dangerouslySetInnerHTML={{ __html: errorText }} />
    </Label>
  )
}

export default ErrorLabel

const Label = styled.a`
  color: red;
`
