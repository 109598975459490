import React from 'react'
import fb from './../icons/fb.png'
import ig from './../icons/ig.png'
import tel from './../icons/tel.png'
import tg from './../icons/tg.png'
import wa from './../icons/wa.png'
import yt from './../icons/yt.png'
import { getCity, texts } from './../utils/utils'
import { styled } from '@mui/material'

const MobileContacts = () => {
  const { contacts } = texts[getCity()]
  const telNr = String(contacts)?.replaceAll(' ', '')

  return (
    <>
      <A target='_blank' rel='noreferrer' href={`tel:${telNr}`} style={{ color: 'white' }}>
        <img src={tel} alt='phone]' width='35px' />
      </A>
      <A target='_blank' rel='noreferrer' href={`https://wa.me/${telNr}`} style={{ color: 'white' }}>
        <img src={wa} alt='whatsapp' width='35px' />
      </A>
      <A target='_blank' rel='noreferrer' href={`https://telegram.me/${telNr}`} style={{ color: 'white' }}>
        <img src={tg} alt='telegram' width='35px' />
      </A>
    </>
  )
}

export default MobileContacts

export const FacebookIcon = () => (
  <A href='https://www.facebook.com/rigakayaking' target='_blank' rel='noreferrer'>
    <img src={fb} alt='facebook' width='35px' />
  </A>
)

export const YoutubeIcon = () => (
  <A href='https://www.youtube.com/channel/UCQvWvS4_0lGtecg0JBks_zQ' target='_blank' rel='noreferrer'>
    <img src={yt} alt='youtube' width='35px' />
  </A>
)

export const InstagramIcon = () => (
  <A href='https://www.instagram.com/riga_kayaking/' target='_blank' rel='noreferrer'>
    <img src={ig} alt='instagram' width='35px' />
  </A>
)

const A = styled('a')({
  padding: '0px'
})
