import './styles/app.css'
import './styles/colors.css'
import './styles/day-picker.css'
import App from './App'
import locale from 'date-fns/locale/en-US'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GlobalStoreContextProvider } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'
import { H } from 'highlight.run'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { createRoot } from 'react-dom/client'
import { createTheme, ThemeProvider } from '@mui/material'

// After
const container: any = document.getElementById('root')
const root = createRoot(container)

// TODO: create theme
const theme = createTheme({
  palette: {
    mode: 'dark'
  },
  components: {}
})

if (locale && locale.options) {
  locale.options.weekStartsOn = 1
}

// https://app.highlight.run/ error logger
H.init('jd4r1wg5')

root.render(
  <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
    <ThemeProvider theme={theme}>
      <GlobalStoreContextProvider>
        <App />
      </GlobalStoreContextProvider>
    </ThemeProvider>
  </LocalizationProvider>
)
