import styled from 'styled-components'
import useScreenType from 'components/reusable/Hooks/useScreenType'
import { observer } from 'mobx-react'
import { scrollToId } from 'utils/utils'
import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'

const ToggleInstructions = observer(() => {
  const {
    translate: {
      map,
      buttons: { buttons }
    }
  } = useStore()
  const { isMobile } = useScreenType()

  const goToButtons = async () => scrollToId('map-button')

  return (
    <InstructionsContainer>
      <Text isMobile={isMobile}>
        {map.clickOn}
        <SmallRedButton onClick={goToButtons}>{buttons}</SmallRedButton>
        {map.toToggleRoutes}
      </Text>
    </InstructionsContainer>
  )
})

export default ToggleInstructions

const InstructionsContainer = styled.div`
  position: relative;
  z-index: 10;
  width: inherit !important;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  bottom: 10px;
`

const Text = styled.div<{ isMobile: boolean }>`
  background-color: black;
  padding: 10px;
  color: white;
  border-radius: 20px;
  max-width: 100%;

  display: flex;
  width: ${({ isMobile }) => (isMobile ? '200px' : '80%')};
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
`

const SmallRedButton = styled.div<{}>`
  background: var(--main-red);
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 15px;
  cursor: pointer;
`
