export const MAP_API_KEY = process.env.REACT_APP_MAP_KEY ?? ''

export const DEFAULT_LAT = 56.9496
export const DEFAULT_LNG = 24.1052

export const MAP_CENTER = {
  lat: DEFAULT_LAT,
  lng: DEFAULT_LNG
}

export const MAP_OPTIONS = {
  // disableDefaultUI: true,
  zoomControl: true,
  disableAutoPan: true,
  disableAutoZoom: true,
  streetViewControl: false,
  mapTypeControl: false,
  suppressInfoWindows: false,
  preserveViewport: true,
  mapTypeId: 'satellite'
}
