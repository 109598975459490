import React from 'react'
import styled from 'styled-components'
import MobileContacts, { FacebookIcon, InstagramIcon, YoutubeIcon } from 'components/MobileContacts'
import useScreenType from 'components/reusable/Hooks/useScreenType'

const Footer = () => {
  const year = new Date().getFullYear()
  const { isMobile } = useScreenType()

  return (
    <FooterContainer isMobile={isMobile}>
      <C isMobile={isMobile}>Riga Kayaking © {year}</C>
      <FooterIconBox>
        <MobileContacts />
        <div style={{ borderLeft: '1px solid white' }} />
        <FacebookIcon />
        <YoutubeIcon />
        <InstagramIcon />
      </FooterIconBox>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.div<{ isMobile: boolean }>`
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  width: 90vw;
  max-width: 1000px;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  gap: 30px;
`
const FooterIconBox = styled.div`
  display: flex;
  gap: 10px;
`

const C = styled.div<{ isMobile: boolean }>`
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'start')};
  font-size: 0.8em;
  width: 50%;
`
