import MediumButton from '../Elements/Buttons/MediumButton'
import React from 'react'
import styled from 'styled-components'
import { ROUTE, texts, getCity } from 'utils/utils'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../Contexts/GlobalStoreContext'

const Err = observer(() => {
  const navigate = useNavigate()
  const { contacts } = texts[getCity()]

  const onBtnClick = () => {
    navigate(ROUTE.home)
  }
  const {
    translate: {
      error: { header, foundError, pleaseContact },
      buttons: { tryAgain }
    }
  } = useStore()
  return (
    <ErrorContainer>
      <h1>{header}</h1>
      <span>
        <p>{foundError}</p>
        <p>{pleaseContact.replace('{contacts}', contacts)}</p>
      </span>
      <MediumButton onClick={onBtnClick} bigRedButton>
        <h3>{tryAgain}</h3>
      </MediumButton>
    </ErrorContainer>
  )
})

export default Err

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 90vw;
  height: 100vh;
  justify-content: center;
  gap: 30px;
`
