import { useStore } from 'components/ProjectSpecificComponents/Contexts/GlobalStoreContext'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const RouteInfo = observer(() => {
  const {
    translate: {
      routes: { about }
    }
  } = useStore()

  return (
    <RouteInfoContainer>
      <p dangerouslySetInnerHTML={{ __html: about }} />
    </RouteInfoContainer>
  )
})

export default RouteInfo

const RouteInfoContainer = styled.div`
  width: 1000px;
  max-width: 90vw;
`
