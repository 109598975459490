import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { COUNTRY_CODES } from 'utils/countryCode'
import { styled } from '@mui/material'
import { ICountryType } from 'utils/interfaces'
interface IProps {
  value?: any
  onChange?: any
}
export default function CountrySelect({ value, onChange }: IProps) {
  return (
    <Autocomplete
      disableClearable
      options={COUNTRY_CODES}
      autoHighlight
      value={value}
      onChange={(event: any, newValue: string | null) => {
        onChange(newValue)
      }}
      getOptionLabel={(option: ICountryType) => option.phone}
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{
            '& > img': { mr: 2, flexShrink: 0 },
            backgroundColor: 'black',
            color: 'white'
          }}
          {...props}
        >
          <img
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=''
          />
          ({option.code}) {option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <StyledInput
          {...params}
          size='small'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
}

const StyledInput = styled(TextField)({
  border: '2px solid var(--gray-c2)',
  outline: 'none',
  width: '100px',
  color: 'white !important',
  borderRadius: '6px 0px 0px 6px',
  background: 'black !important',

  '& .MuiInputBase-root': {
    background: 'black !important'
  },
  '& .MuiInputBase-input': {
    background: 'black !important',
    color: 'white'
  },
  '& .MuiOutlinedInput-root': {
    padding: '2px 0px 1px 0px !important'
  },
  '& .MuiInputBase-inputSizeSmall': {
    paddingTop: 5,
    paddingBottom: 3
  },
  '& .MuiInputBase-input::placeholder': {
    color: 'white'
  }
})
