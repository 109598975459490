import { TBooking } from 'utils/interfaces'
import { T_KMZ_TYPE } from '../Elements/Map/KMZ_LAYERS'
import en from './../../../translations/en.json'

export enum ELng {
  'EN' = 'English',
  'RU' = 'Russian',
  'LV' = 'Latviešu'
}

interface IKayakCount {
  one_seat_kayak: number
  two_seat_kayak: number
  three_seat_kayak: number
}
interface IDefaultState {
  language: ELng
  translations: any
  translate: typeof en
  isTestMode: boolean
  kayakCount: IKayakCount | undefined
  kayakingRoutes: TKayakingRoute[]
  activeKMZ_KEYS: T_KMZ_TYPE[]
  isLoaderOverlayOpen: boolean
  orderCompleted: any
  userPhoneNumber: string
  smsContent: string
  number: string | undefined
  email: string | undefined
}

const defaultTranslations = {
  [ELng.EN]: { ...en }
}

const DEFAULT_STATE: IDefaultState = {
  language: (localStorage.getItem('lng') as ELng) ?? ELng.EN,
  translations: defaultTranslations,
  translate: defaultTranslations[ELng.EN],
  isTestMode: false,
  kayakCount: undefined,
  kayakingRoutes: [],
  activeKMZ_KEYS: ['All'],
  isLoaderOverlayOpen: false,
  orderCompleted: 'unchecked',
  userPhoneNumber: undefined,
  smsContent: undefined,
  number: undefined,
  email: undefined,
}

export default function createStore() {
  return {
    language: DEFAULT_STATE.language,
    setLanguage(lng: ELng) {
      localStorage.setItem('lng', lng)
      this.language = lng
      this.translate = this.translations[lng]
    },

    translate: DEFAULT_STATE.translate,

    translations: DEFAULT_STATE.translations,
    setTranslations(translations) {
      this.translations = translations
    },

    kayakCount: DEFAULT_STATE.kayakCount,
    setKayakCount(data) {
      return (this.kayakCount = data)
    },
    isTestMode: DEFAULT_STATE.isTestMode,
    setTestMode(bool: boolean) {
      this.isTestMode = bool
    },

    kayakingRoutes: DEFAULT_STATE.kayakingRoutes,
    setKayakingRoutes(routes: TKayakingRoute[]) {
      this.kayakingRoutes = routes
    },

    activeKMZ_KEYS: DEFAULT_STATE.activeKMZ_KEYS,
    filterKMZ_KEYS(key: T_KMZ_TYPE) {
      return (this.activeKMZ_KEYS = [key])
    },

    isLoaderOverlayOpen: DEFAULT_STATE.isLoaderOverlayOpen,
    setLoaderOverlayOpen(bool: boolean) {
      return (this.isLoaderOverlayOpen = bool)
    },

    orderCompleted: DEFAULT_STATE.orderCompleted,
    setOrderCompleted(value: any) {
      // Can be undefined, 'unchecked', true
      return (this.orderCompleted = value)
    },

    userPhoneNumber: DEFAULT_STATE.userPhoneNumber,
    setUserPhoneNumber(number: string) {
      return (this.userPhoneNumber = number)
    },

    smsContent: DEFAULT_STATE.smsContent,
    setSmsContent(sms: string) {
      return (this.smsContent = sms)
    },

    // Add state properties for number and email
    number: DEFAULT_STATE.number,
    email: DEFAULT_STATE.email,

    // Method to set the number
    setNumber(newNumber: string) {
      this.number = newNumber;
    },

    // Method to set the email
    setEmail(newEmail: string) {
      this.email = newEmail;
    },
  }
}

export type TKayakingRoute = {
  id: string
  lengthFrom: number | null
  lengthTo: number
  label: string | undefined
  imgUrl: string
  difficulty: string
  startingPointKey: string
  desc: string
  routeKMZ: string
  km: number | null
  cardImgUrl: string
  isPublic: boolean
}
