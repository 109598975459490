import React from 'react'
import rk_with_bg from './../icons/rk_with_bg.png'
import styled from 'styled-components'
import useScreenType from './reusable/Hooks/useScreenType'
import { ROUTE } from 'utils/utils'
import { useNavigate } from 'react-router-dom'

const RKLogoHeader = () => {
  const { isMobile } = useScreenType()
  const navigate = useNavigate()
  const onLogoClick = () => navigate(`${ROUTE.home}`)

  return (
    <RKLogo onClick={onLogoClick}>
      <img src={rk_with_bg} height={isMobile ? '60px' : '100px'} alt='rk_white_logo' width='auto' />
    </RKLogo>
  )
}

export default RKLogoHeader

const RKLogo = styled.div`
  position: absolute;
  width: 33vw;
  left: 10vw;
  top: 50px;
  border-radius: 0px 15px 15px 0px;
  padding: 15px 40px 20px 20px;
  cursor: pointer;w
`
