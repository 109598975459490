import './styles/keyframes.css'
import React from 'react'
import Whitespace from '../Whitespace'
import rk_white from './../../../../icons/rk_white.png'
import styled from 'styled-components'
import { CircularProgress } from '@mui/material'

interface Props {
  active?: boolean
}

const GlobalOverlayLoader = ({ active }: Props) => {
  if (!active) return <></>

  return (
    <OverlayBase>
      <LoaderBox>
        <img alt='rk_logo' width='200px' height='auto' src={rk_white} />
        <Whitespace x='2' />
        <CircularProgress sx={{ color: 'white' }} />
      </LoaderBox>
    </OverlayBase>
  )
}

export default GlobalOverlayLoader

const LoaderBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`
const OverlayBase = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;

  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
`
