import CoverPhotoButtons from 'components/CoverPhotoButtons'
import React from 'react'
import styled from 'styled-components'
import useScreenType from 'components/reusable/Hooks/useScreenType'
import { TKayakingRoute } from 'components/ProjectSpecificComponents/Stores/GlobalStore'

interface Props {
  route: TKayakingRoute
}

const CardCover = ({ route }: Props) => {
  const { isMobile } = useScreenType()

  return (
    <CoverContainer bgImg={route.imgUrl} className='main_image'>
      <CoverPhotoButtons />
    </CoverContainer>
  )
}

export default CardCover

const CoverContainer = styled.div<{ bgImg: string }>`
  display: flex;
  flex-dection: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 65vh;
  position: relative;
  background-position: bottom;
  background-image: ${({ bgImg }) => `url(${bgImg})`};
`
